import { ToTop } from "./ToTop"




function Disclaimer()  {
    return (

        <div className="w-[100%] flex flex-col justify-center items-center lg:p-20 sm:p-4 pt-4">
             <h1 className='w-[100%] text-center  2xl:text-6xl backdrop-blur-sm pt-2 sm:text-4xl mb-4'> Disclaimer</h1>
					
				<div className="w-[80%] ">
				<div >
					<div >
                    
                    <ul className="list-disc">
                    <li  >
                    <b> Satyanarayan Sekhsaria Pvt. Ltd. (SSPL)</b>
                    <span  >&nbsp;is the owner of all text, graphics and trademarks displayed&nbsp;on the site&nbsp;</span>
                    <a href="http://ssp-limited.com/"><span  >http://ssp-limited.com/</span></a>
                    <span  >&nbsp;which is owned by </span><b>SSPL</b>
                    <span  >. No reproduction of any part of this site may be made nor any part be modified or incorporated in any other work, except for strictly personal use. </span> 
                    <b>SSPL</b><span  > reserves all other rights pertaining to this site. </span></li>
                    </ul>
                    <ul className="list-disc">
                    <li><b><i>Products Features and Specifications</i> :</b></li></ul>
                    <ul className="list-disc"> 
                    <li><span  >namely Technical, Physical and Benefits mentioned in the entire product specifications pages are indicative. These specifications represent the broader features of the product line. The actual product may differ from that shown on the website depending upon the Geographical, Soil &amp; Climatic conditions. All these features are dependent on many controllable and uncontrollable factors like geographies, climate, product handling, etc. All products may or may not be available in all geographical areas. </span>SSPL<span  > does not guarantee these features and specifications to the buyer and/or user of products. </span>SSPL<span  > will not be held responsible and liable to pay for any damages in monetary or non-monetary form for any loss or damage occurred to any user and/or buyer of the products due to reasons whatsoever it may be, whether known or unknown in any relevant or irrelevant circumstances. Your eligibility to receive any product may be subject to approval of </span>SSPL<span  >.</span></li>
                    </ul>
                    <ul className="list-disc"><li  ><b>SSPL</b><span  > has taken due care to provide the user/visitor/member with accurate prices and product information, however, in case of any typographical errors or negligence or errors of omission resulting into incorrect prices or information, </span><b>SSPL</b><span  > holds the sole right and discretion to correct/modify the same without any claims and liabilities and the user/visitor/member has to pay the correct price without any recourse.</span></li>
                    </ul>
                    <ul className="list-disc"><li  ><span  >The information on this site has been concluded in good faith and is for general purpose only. In spite of our best efforts, the information on this Web Site may become out of date. No representation or warranty of any kind, express or implied, is given as regards its accuracy, adequacy, completeness, timeliness or fitness of any specific purpose. It should not be relied upon for any specific purpose. You must seek the advice of a professional to advise you on your specific situation. Under no circumstance will </span><b>SSPL</b><span  > or its subsidiaries, employees, associates or agents be liable to any party for any loss, damage or expense arising out of any access to, or use of, this site. Any reliance you place on such information is therefore strictly at your own risk.</span></li></ul>
                    <ul className="list-disc">
                    <li  ><span  >The Web-site&nbsp;</span><a href="http://ssp-limited.com/"><span  >http://ssp-limited.com/</span></a><span  >&nbsp;represents its company </span><b>Satyanarayan Sekhsaria Pvt. Ltd.</b><span  > by word “SSPL”. This is to be construed in the manner referred to &amp; is no way related to any of the group’s business entity of whatsoever nature.</span></li></ul>
                    <ul className="list-disc">
                    <li  ><a href="http://ssp-limited.com/"><span  >http://ssp-limited.com/</span></a><span  >&nbsp;is the domain &amp; website owned by </span><b>Satyanarayan Sekhsaria Pvt. Ltd. (SSPL)</b><span  > is the sole representative owner of this website. </span><b>SSPL</b><span  > reserves the right, in its sole discretion, to terminate your access to all or part of this site, with or without notice.</span></li></ul>
                    <ul className="list-disc"><li  ><b>SSPL</b><span  > expressly disclaims from any claims or liability arising out of any errors, omissions, delay, failure, interruption, or corruption of any data or other information or malfunctioning of our services through our website etc.</span></li></ul><ul className="list-disc"><li  ><span  >In no event or circumstances shall </span><b>SSPL</b><span  > (in its collective meaning) will be held liable for any claims &amp; liability for any content/material/information provided in any or all communication means (including but not limited to our website, email, application, text messages, pamphlets, leaflets, cards etc) used for marketing or supporting activities.</span></li></ul>
                    <ul className="list-disc"><li  ><b>SSPL</b><span  > holds the sole right to change/amend/discontinue any of its terms and conditions including privacy policy &amp; other policies, without giving its member/users prior notice and without any claims and liability of any kind.</span></li></ul><ul><li  ><span  >The Materials may not be used (except as provided in these Provisions or in the text on the Web Site) without the prior written permission of </span><b>SSPL</b><span  >, its affiliated entities or the third party owner. You may however, download materials for non-commercial, personal use only, provided you also retain and do not alter any copyright and other proprietary rights notices contained on the Materials.</span></li></ul>
                    <ul className="list-disc"><li  ><span  >Though our website and application is secured by appropriate anti-virus softwares still we shall not be held responsible for any claims &amp; liability for any damages &amp; viruses that may affect the user’s/visitor’s/member’s equipment/system on while accessing or visiting or browsing our website or application or for any downloading etc.</span></li></ul>
                    <ul className="list-disc"><li><b><i>Collection of Personally Identifiable Information and other Information:</i></b></li></ul>
                    <ul ><li   ><ul className="ml-8"><li  ><span  >When you use our Website, </span><b>SSPL</b><span  > collects and stores your personal information which is provided by you from time to time. Our primary goal in doing so is to provide you a safe, efficient, smooth and customized experience. This allows us to provide services and features that most likely meet your needs. More importantly, while doing so </span><b>SSPL</b><span  > collects personal information from you that </span><b>SSPL</b><span  > considers necessary for achieving this purpose. </span></li></ul></li></ul>
                    <ul ><li   ><ul className="ml-8"><li  ><b>SSPL</b><span  > uses this information to do internal research on our users’ demographics, interests, and behaviour to understand, protect and serve </span><b>SSPL</b><span  > users. This information is compiled and analyzed on an aggregated basis. This information may include the URL that you just came from (whether this URL is on our Website or not), which URL you next go to (whether this URL is on our Website or not), your computer browser information, and your IP address. Your IP address is also used to help identify you and to gather broad demographic information.</span></li></ul></li></ul>
                    <ul ><li   ><ul className="ml-8"><li  ><span  >In general, you can browse the Website without telling </span><b>SSPL</b><span  > who you are or revealing any personal information about yourself. Once you give </span><b>SSPL</b><span  > your personal information, you are not anonymous to us. Where possible, </span><b>SSPL</b><span  > indicates which fields are required and which fields are optional.</span></li></ul></li></ul>
                    <ul className="list-disc"><li><b><i>Privacy Policy</i></b></li></ul><ul><li   ><ul className="ml-8"><li  ><b>SSPL</b><span  > privacy policy is subject to change at any time without notice. To make sure you are aware of any changes, please review this policy periodically.</span></li></ul></li></ul>
                    <ul ><li   ><ul className="ml-8"><li  ><span  >By visiting this Website you agree to be bound by the terms and conditions of this Privacy Policy. If you do not agree please do not use or access our Website.</span></li></ul></li></ul>
                    <ul className="ml-8"><li   ><ul><li  ><span  >By mere use of the Website, you expressly consent to our use and disclosure of your personal information in accordance with this Privacy Policy. </span></li></ul></li></ul ><ul className="list-disc"><li><b><i>Cookies</i></b></li></ul><ul><li   >
                    <ul className="ml-8"><li  ><span  >A “cookie” is a small piece of information stored by a web server on a web browser so it can be later read back from that browser. Cookies are useful for enabling the browser to remember information specific to a given user. </span><b>SSPL</b><span  > places both permanent and temporary cookies in your computer’s hard drive. The cookies do not contain any of your personally identifiable information.</span></li></ul></li></ul>
                    <ul className="ml-8"><li   ><ul><li  ><b>SSPL</b><span  > use cookies on certain pages of the Website to help analyse our web page flow, measure promotional effectiveness, and promote trust and safety. “Cookies” are small files placed on your hard drive that assist us in providing our services. Cookies can also help us provide information that is targeted to your interests. Most cookies are “session cookies,” meaning that they are automatically deleted from your hard drive at the end of a session. You are always free to decline our cookies if your browser permits, although in that case you may not be able to use certain features on the Website.</span></li></ul></li></ul>
                    <ul className="ml-8"><li   ><ul><li  ><span  >Additionally, you may encounter “cookies” or other similar devices on certain pages of the Website that are placed by third parties. </span><b>SSPL</b><span  > does not control the use of cookies by third parties</span><span  >.</span></li></ul></li></ul><ul className="list-disc"><li  ><span  >To the fullest extent permitted under applicable law, </span><b>SSPL</b><span  > or our suppliers shall not be liable for any indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses arising out of or in connection with the Website, its services or this contract/agreement/terms &amp; conditions.</span></li></ul><ul className="list-disc"><li  ><span  >Though </span><b>SSPL</b><span  > shall make sure uninterrupted functioning of our website/application etc however </span><b>SSPL</b><span  > will not be liable, in case the same are temporarily out of service due to some technical limitation/errors beyond our control.</span></li></ul>
                    <ul className="list-disc"><li  ><span  >If you feel that any of the linked website contains any unlawful or illegal content, please contact </span><b>SSPL</b><span  > as the address mentioned on the Web page “Contact us”.</span></li></ul><ul ><li className="list-disc" ><b><i>Your Consent</i></b></li><li   ><ul className="ml-8"><li  ><span  >By using the Website and/ or by providing your information, you consent to the collection and use of the information you disclose on the Website in accordance with this Privacy Policy, including but not limited to Your consent for sharing your information as per this privacy policy.</span></li></ul></li></ul><ul><li   ><ul className="ml-8"><li  ><span  >If </span><b>SSPL</b><span  > decides to change the privacy policy, </span><b>SSPL</b><span  > will post those changes on this page so that you are always aware of what information </span><b>SSPL</b><span  > collects, how </span><b>SSPL</b><span  > uses it, and under what circumstances </span><b>SSPL</b><span  > discloses it.</span></li></ul></li></ul><ul ><li className="list-disc"><b><i>Applicable Law and Jurisdiction</i></b></li></ul>
                    <ul className="ml-8"><li   ><ul><li  ><span  >This Website shall be governed by and interpreted and construed in accordance with the laws of India. The place of jurisdiction shall be exclusively in </span><b>Mumbai</b><span  >, India.</span></li></ul></li></ul></div>
				</div>
				</div>
		<ToTop />
		</div>
    )
}

export default Disclaimer